a{
    text-decoration: none;
}
.popup-content{
        position:absolute;
top:50%;
left:40%;
margin-top:-50px; /* this is half the height of your div*/  
margin-left:-80px; 
  background: #c6bcbc;
padding: 30px;
border-radius: 30px;
color: #fff;
font-size: 21px;

}
.text-black{
  color: black;
  text-align: center;
}